.Loader_card__EpPUs {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
}

@keyframes Loader_placeholderShimmer__ZzX5N {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.Loader_shimmer-animation__MbpS4 {
  background-color: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: Loader_placeholderShimmer__ZzX5N;
  animation-timing-function: linear;
}
.Loader_loader-shimmer-banner__k3Dle.Loader_full__sqarv {
  min-height: 100vh;
}
.Loader_loader-shimmer-banner__k3Dle {
  min-height: calc(100vh - 9.75rem);
  background-size: 800px 72rem;
  border-radius: 5px;
  margin-bottom: 12px;
}

.Loader_fpl-container__3JwLD {
  width: 100%;
  display: flex;
}
.Loader_fpl-container-left__dd6UR {
  min-height: 100vh;
  width: 20%;
  margin-right: 15px;
}
.Loader_fpl-container-right__YJ6TE {
  min-height: 100vh;
  width: 80%;
  margin-right: 20px;
}

.Loader_row__iqjH8 {
  height: 100%;
  width: 100%;
  padding: 10px;
}

.Loader_row__iqjH8 .Loader_loader-shimmer-banner__k3Dle {
  min-height: unset;
  height: 100%;
  background-size: unset;
}
.Loader_column__ZxiOg {
  grid-row: span 2;
  grid-column: 1;
  height: 100%;
  width: 200px;
  margin: 10px;
}

.Loader_column__ZxiOg .Loader_loader-shimmer-banner__k3Dle {
  height: calc(100vh - 20px);
}

@media (max-width: 1023px) {
  .Loader_column__ZxiOg {
    grid-row: span 1;
    grid-column: 1;
    height: 100%;
    height: calc(100% - 20px);
    width: 20px;
    margin: 10px;
  }

  .Loader_column__ZxiOg .Loader_loader-shimmer-banner__k3Dle {
    height: 100%;
    min-height: unset;
  }
}

.Loader_full__sqarv {
  margin: 10px;
  width: 100%;
  height: 100%;
}

.DebugPanel_debug_panel__G_jGS {
  border: 1px solid #ccc;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20em;
  background-color: #fff;
}

.DebugPanel_handle__On42_ {
  cursor: move;
  background: #eee;
  padding: 0.5em;
  width: 100%;
  display: block;
}

.DebugPanel_body__DfoJ2 {
  padding: 1em;
}

@media print {
  .DebugPanel_debug_panel__G_jGS {
    display: none;
  }
}

.Footer_container__Dj1D9 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #393939;
  padding: 0.75rem 1.25rem;
  flex-basis: 2.75rem;
  flex-shrink: 0;
  height: 2.75rem;
  color: #ffffff;
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
}

.Footer_link-wrapper__GrO9Q {
  flex-wrap: nowrap;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.Footer_links__LGHlK {
  display: flex;
  font-weight: 400;
  padding: 0;
  margin: 0;
  -webkit-margin-start: 1.25rem;
          margin-inline-start: 1.25rem;
  gap: 0.5rem;
  font-size: 0.75rem;
}

.Footer_links__LGHlK .Footer_list___KSK1 {
  list-style-type: none;
  -webkit-padding-end: 0.5rem;
          padding-inline-end: 0.5rem;
}
.Footer_links__LGHlK .Footer_action__f5l9h {
  text-decoration: none;
  color: #ffffff;
}

.Footer_container__Dj1D9 .Footer_copyright__1v3uR {
  letter-spacing: 0.004rem;
  font-size: 10px;
}

.Footer_links__LGHlK .Footer_list___KSK1:not(:last-child) {
  -webkit-border-end: 0.0625rem solid #ffffff;
          border-inline-end: 0.0625rem solid #ffffff;
}

@media (max-width: 1023px) {
  .Footer_container__Dj1D9 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
  }
  .Footer_link-wrapper__GrO9Q {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    flex-grow: 0;
  }
  .Footer_links__LGHlK {
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    margin-top: 1rem;
  }

  .Footer_container__Dj1D9 p {
    margin-bottom: 0;
  }
}

@media print {
  .Footer_container__Dj1D9 {
    display: none;
  }
}

.Layout_skeleton__5Ht5y:empty {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: Layout_loading__J7A4z;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f3f4f6 38%, #fafafa 54%);
  background-size: 1000px 640px;
  position: relative;
}

.Layout_container__m2XBw {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 3.75rem 1fr;
  height: 100vh;
}
.Layout_header__SXwWG {
  display: contents;
}

.Layout_container__m2XBw .Layout_skeleton__5Ht5y:first-child {
  min-width: 12.5rem;
  min-height: 100vh;
}

.Layout_home-content__R21_p {
  padding: 1rem;
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
}

.Layout_main-content__gDtPn {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  grid-column-start: 2;
  grid-row: 1;
  grid-row-end: none;
}

#Layout_main__65zHd {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

#Layout_main__65zHd > tr {
  min-height: 100%;
}

@media (max-width: 1023px) {
  .Layout_main-content__gDtPn {
    grid-column: 1/3;
    margin-top: 2.72rem;
    grid-row: 2;
  }

  #Layout_main__65zHd {
    height: 100vh;
  }

  .Layout_container__m2XBw .Layout_skeleton__5Ht5y:first-child {
    min-width: auto;
    min-height: auto;
  }
}

@keyframes Layout_loading__J7A4z {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.Layout_skip-main__vPzUQ {
  position: absolute;
  left: -9999px;
  z-index: 3000;
  padding: 0.5em;
  background-color: black;
  color: white;
  opacity: 0;
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
}

.Layout_skip-main__vPzUQ:dir(rtl) {
  left: 9999px;
}

.Layout_skip-main__vPzUQ:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

@media print {
  .Layout_skip-main__vPzUQ:dir(rtl) {
    display: none;
  }
}

.Layout_table-header__jk3tq,
.Layout_table-header__jk3tq > tr,
.Layout_table-header__jk3tq > tr > th,
.Layout_table-header-content__ediNx,
#Layout_main__65zHd > tr > td,
.Layout_main-content__gDtPn > tfoot,
.Layout_main-content__gDtPn > tfoot > tr,
.Layout_main-content__gDtPn > tfoot > tr > td {
  display: contents;
}

.Layout_table-header-content__ediNx {
  text-align: left;
}

.Layout_table-header-content__ediNx > :not(nav, *) {
  max-height: 3.75rem;
}

@media print {
  .Layout_container__m2XBw {
    display: contents;
    height: auto;
  }

  .Layout_main-content__gDtPn {
    display: table;
    overflow: visible;
    page-break-after: avoid;
    page-break-inside: avoid;
    margin-top: 0;
    width: 100%;
  }

  .Layout_table-header__jk3tq {
    display: table-header-group;
  }

  .Layout_header__SXwWG {
    display: none;
  }

  #Layout_main__65zHd {
    height: unset;
    display: table-row;
  }
}

.ToastContainer_toast-wrapper__j4jhd {
  position: fixed;
  width: 100%;
  display: flex;
  right: 1rem;
  top: 4.75rem;
  transform: translate(0);
  z-index: 10000;
}
@media (max-width: 767px) {
  .ToastContainer_toast-wrapper__j4jhd {
    right: 0;
    left: 0;
  }
  .ToastContainer_toast-wrapper__j4jhd > div {
    justify-content: center;
  }
}
@media (max-width: 1023px) {
  .ToastContainer_toast-wrapper__j4jhd {
    top: 5.75rem;
  }
}

.EmulationIndicator_emulation-container__mIWGc {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.25rem solid #ea9c00;
  z-index: 9999;
}

@media print {
  .EmulationIndicator_emulation-container__mIWGc {
    display: none !important;
  }
}

.EmulationIndicator_label-spacer__12vIz {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.EmulationIndicator_label-container__aNt0L {
  background-color: #ea9c00;
  padding: 0.375rem 0.75rem;
  line-height: 0;
}

.EmulationWatcher_icon__QB35Y {
  font-size: 3em;
  display: flex;
  justify-content: center;
}

.EmulationWatcher_footer__6tnaC {
  display: flex;
  justify-content: center;
}

:root {
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  overflow-x: hidden;
}

body {
  background-color: rgb(255, 255, 255);
}

a {
  color: inherit;
  text-decoration: none;
}

.pendo-resource-center-badge-notification-bubble {
  width: 14px !important;
  height: 14px !important;
  top: 8px !important;
  left: 20px !important;
}

.pendo-notification-bubble-unread-count {
  font-size: 8px !important;
  right: -6.45px !important;
  top: 54% !important;
}

._pendo-resource-center-home-list {
  direction: ltr;
}

@media print {
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

@media (max-width: 767px) {
  ._pendo-resource-center-global-container {
    top: 0 !important;
    max-width: 100vw;
    max-height: 100vh;
  }

  ._pendo-resource-center-global-container ol {
    overflow: hidden;
  }

  ._pendo-home-view-bubble {
    padding: 0px 6px !important;
    font-size: 12px !important;
    height: 18px !important;
    top: 30% !important;
    right: 10px !important;
  }
}

